import { React } from 'react';
import axios from 'axios';
//import { toast } from "react-toastify";

const host = 'https://cocan-courrier.com/ws/api/v1/';

export const requestAxiosMulti = async (data, url) => {
    let mx = { status: 0, data: null };
    let token = null;
    try {
        const user = JSON.parse(localStorage.getItem('cocanUser'));
        token = user['token'];
        console.log(token)
    } catch (e) {}
    const requestOptions = {
        method: 'POST',
        body: data,
        headers: { Authorization: token }
    };
    try {
        const response = await fetch(`${host}${url}`, requestOptions);
        const req = await response.json();
        mx.data = req;
        mx.status = response.status;
        console.log(response);
        if (response.status == 200) {
        } else if (response.status == 404 || response.status == 403) {
        } else {
        }
    } catch (e) {}
    return mx;
};

export const requestAxios = async (data, url, type) => {
    let token = null;
    try {
        const user = JSON.parse(localStorage.getItem('cocanUser'));
        token = user['token'];
    } catch (e) {}
    let mx = null;
    await axios({
        url: `${host}${url}`,
        method: `${type}`,
        headers: {
            Authorization: token !== null ? token : ''
        },
        data
    })
        .then((res) => {
            mx = res;
        })
        .catch((error) => {
            mx = { status: 400 };
            if (error.response) {
            } else if (error.request) {
                mx = { status: 400 };
            } else {
            }
        });
    return mx;
};
