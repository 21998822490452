import React,{useState,useEffect,useRef} from "react";
import { Stepper } from 'react-form-stepper';
import { Form, Input, Label, FormFeedback, FormText, FormGroup,Col, Row, Button } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { requestAxios } from "common/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose,faQuestion } from '@fortawesome/free-solid-svg-icons'
import success from "../../../assets/img/success.png"
import ballon from "../../../assets/img/ballon.gif"
import ballon2 from "../../../assets/img/ballon2.gif"
import SweetAlert from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';

const Index = () => {
    const [currentTab,setCurrentTab] = useState(0)

    const tableColumns = [
        {
      name: 'N°',
      button: true,
      width: 50,
      cell: (row) => (
          <div>
              {row.idEvent}
          </div>
      )
    },
    {
        name: 'Stade',
        cell: (row) => (
            <div>
                {row.nameStadium}
            </div>
        )
      },
    {
        name: 'Evenement',
        cell: (row) => (
            <div>
                {row.name}
            </div>
        )
      },
      {
        name: currentTab == 0 ? 'CATEGORIE 3':'HOSPITALITE',
        button: true,
        width: 350,
        cell: (row) => (
            <div>
                {row.tickets[0].price+" XOF"}
            </div>
        )
      },
      {
        name: 'Quantité',
        button: true,
        width: 200,
        visible:false,
        cell: (row) => (
            <div>
                <Input type="number"  defaultValue={row.tickets[0].qty}  onChange={(v)=>{
                    
                    var index = eventList.indexOf(row);
                    if(index!=-1 ){
                         if(v.target.value.trim().length ==0){
                            eventList[index].tickets[0].qty = 0;
                            setEventList(eventList)
                            setCount(count * 9)
                         }
                         else{
                            eventList[index].tickets[0].qty = parseInt(v.target.value);
                         setEventList(eventList)
                         
                         setCount(count*parseInt(v.target.value))
                         }
                    }
                    var c = 0;
                    var resEvent = eventList
    var search = (resEvent.filter((x)=> x.isSelected == false && (x.tickets[0].qty>0 || x.tickets[1].qty>0 || x.tickets[2].qty>0 )));
        for(var i = 0;i<search.length;i++){
            var oneEvent = search[i].tickets;
            for(var j = 0;j<oneEvent.length;j++){
                if(oneEvent[j].qty>0){
                    
                     c += oneEvent[j].qty * oneEvent[j].price;
                }
            }
            
        }
                    setTotal(c);
                }} />
            </div>
        )
      },
      {
        name:currentTab == 0 ?  'CATEGORIE 2':'',
        width: currentTab == 0?0:350,
        cell: (row) => (
            <div>
                {row.isSelected?"":row.tickets[1].price+" XOF"}
            </div>
        )
      },
      {
        name:currentTab == 0 ?   'Quantité':'',
        width: currentTab == 0?0:200,
        cell: (row) => (
            <div>
                {row.isSelected?"":
                <Input type="number"  defaultValue={0}  onChange={(v)=>{
                    
                    var index = eventList.indexOf(row);
                    if(index!=-1){
                        if(v.target.value.trim().length ==0){
                            eventList[index].tickets[1].qty = 0;
                            setEventList(eventList)
                            setCount(count * 90)
                         }
                         else{
                            eventList[index].tickets[1].qty = parseInt(v.target.value);
                         setEventList(eventList)
                         
                         setCount(count*parseInt(v.target.value))
                         }
                    }
                    setEventList(eventList)
                    var c = 0;
                    var resEvent = eventList
    var search = (resEvent.filter((x)=> x.isSelected == false && (x.tickets[0].qty>0 || x.tickets[1].qty>0 || x.tickets[2].qty>0 )));
        for(var i = 0;i<search.length;i++){
            var oneEvent = search[i].tickets;
            for(var j = 0;j<oneEvent.length;j++){
                if(oneEvent[j].qty>0){
                    
                     c += oneEvent[j].qty * oneEvent[j].price;
                }
            }
            
        }
                    setTotal(c);
                }} />}
            </div>
        )
      },
      {
        name: currentTab == 0 ? 'CATEGORIE 1':'',
        width: currentTab == 0?0:350,
        cell: (row) => (
            <div>
                {row.isSelected?"":row.tickets[2].price+" XOF"}
            </div>
        )
      },
      {
        name: currentTab == 0 ?  'Quantité':'',
        width:currentTab == 0?0: 200,
        cell: (row) => (
            <div>
                {row.isSelected?"":
                <Input type="number" defaultValue={0} onChange={(v)=>{
                    
                    var index = eventList.indexOf(row);
                    if(index!=-1){
                        if(v.target.value.trim().length ==0){
                            eventList[index].tickets[2].qty = 0;
                            setEventList(eventList)
                            setCount(count * 9)
                         }
                         else{
                            eventList[index].tickets[2].qty = parseInt(v.target.value);
                         setEventList(eventList)
                         
                         setCount(count*parseInt(v.target.value))
                         }
                    }
                    setEventList(eventList)
                    var c = 0;
                    var resEvent = eventList
    var search = (resEvent.filter((x)=> x.isSelected == false && (x.tickets[0].qty>0 || x.tickets[1].qty>0 || x.tickets[2].qty>0 )));
        for(var i = 0;i<search.length;i++){
            var oneEvent = search[i].tickets;
            for(var j = 0;j<oneEvent.length;j++){
                if(oneEvent[j].qty>0){
                    
                     c += oneEvent[j].qty * oneEvent[j].price;
                }
            }
            
        }
                    setTotal(c);
                }} />}
            </div>
        )
      },
            
      {
        name: 'Prix',
        width: currentTab == 0?0:350,
        cell: (row) => (
            <div>
                {row.isSelected?row.tickets[0].price*row.tickets[0].qty:(row.tickets[0].price*row.tickets[0].qty)+(row.tickets[1].price*row.tickets[1].qty)+row.tickets[2].price*row.tickets[2].qty}
            </div>
        )
      },
     
    ];
    const tableColumns2 = [
        {
      name: 'Id',
      button: true,
      width: 50,
      cell: (row) => (
          <div>
              {row.id}
          </div>
      )
    },
    {
        name: 'Stade',
        cell: (row) => (
            <div>
                {row.nameStadium}
            </div>
        )
      },
    {
        name: 'Evenement',
        cell: (row) => (
            <div>
                {row.name}
            </div>
        )
      },
      {
        name: 'Prix Ticket',
        button: true,
        width: 350,
        cell: (row) => (
            <div>
                {row.tickets[0].price+" XOF"}
            </div>
        )
      },
      {
        name: 'Quantité',
        button: true,
        width: 200,
        cell: (row) => (
            <div>
                <Input type="number"  defaultValue={0}  onChange={(v)=>{
                    
                    var index = eventList.indexOf(row);
                    if(index!=-1 ){
                         if(v.target.value.trim().length ==0){
                            eventList[index].tickets[0].qty = 0;
                            setEventList(eventList)
                            setCount(count * 9)
                         }
                         else{
                            eventList[index].tickets[0].qty = parseInt(v.target.value);
                         setEventList(eventList)
                         
                         setCount(count*parseInt(v.target.value))
                         }
                    }
                    
                }} />
            </div>
        )
      },
      
      {
        name: 'Prix',
        button: true,
        width: 350,
        cell: (row) => (
            <div>
                {(row.tickets[0].price*row.tickets[0].qty)}
            </div>
        )
      },
     
    ];
    const init = async() =>{
       try{
        var mx = await requestAxios({},"cocan/event/lists","POST");
        if(mx.status == 200){
            console.log(mx.data.data)
            setEventList(mx.data.data)
            /*setData(mx.data.data)
            console.log(mx.data.data[1].tickets)
            setTickets(mx.data.data[1].tickets)*/
        }
       }
       catch(e){}
    }
    useEffect(()=>{
        init();
    },[])
    const uuidv4 = () =>{
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
      }
      const windowSize = useRef([window.innerWidth, window.innerHeight]);

      const [display,setDisplay] = useState("none")
    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [rccm,setRccm] = useState("")
    const [ncc,setNcc] = useState("")
    const [geo,setGeo] = useState("")
    const [domain,setDomain] = useState("")
    const [mobile,setMobile] = useState("")
    const [phone,setPhone] = useState("")
    const [nameUser,setNameUser] = useState("")
    const [lastNameUser,setLastNameUser] = useState("")
    const [phoneUser,setPhoneUser] = useState("")
    const [emailUser,setEmailUser] = useState("")
    const [job,setJob] = useState("")
    const [motif,setMotif] = useState("")
    const [qty,setQty] = useState("")
    const [currentIndex,setCurrentIndex] = useState(-1)
    const [total,setTotal] = useState(0)
    const [data,setData] = useState([])
    const [typeBillet,setTypeBillet] = useState([
        {
            name:"GRAND PUBLIC",
            id:0
        },
        {
            name:"HOSPITALITES ",
            id:1
        }
    ])
    const [cateBillet,setCatBillet] = useState([0])
    const [zone,setZone] = useState([
        {
            name:"Abidjan",
            isAbidjan:true
        },
        {
            name:"Intérieur du pays",
            isAbidjan:false
        }
    ])
    const [isAbidjan,setAbidjan]= useState(true)
    const [staduim,setStadium] = useState(0)
    const [ticket,setTicket] = useState(0)
    const [reservation,setReservation] = useState([])
    const [listEvent,setListEvent] = useState([])
    const [count,setCount] = useState(90)
    const [tickets,setTickets] = useState([
    ])
    const [eventList,setEventList] = useState([])
    const [entity,setEntity] = useState([
        {
            name:"Entreprise",
            id:1
        },
        {
            name:"Association / Institution",
            id:2
        }
    ])
    const [entityId,setEntityId]=useState(1)
    const deletes = (v)=>{
        var infos = reservation;
        var index = infos.indexOf(v);
        if(index!=-1){
            infos.splice(index,1)

        }

        setReservation(infos);
        if(infos.length == 0)
        setCount(count*2*40)
    else
    setCount(count*infos.length*2*40)
    }
    const add = () => {
       if(qty>0 ){
        var infos = reservation;
        var newInfo = {
            stadiumInfo:staduim,
            ticket:ticket,
            events:listEvent,
            qty:qty,
            uuid:uuidv4()
        }
        console.log(data)
        console.log(data.filter((x)=>x.id == staduim)[0]["events"].filter((x)=>x.isSelected).length)
        for(var i = 0;i<data.filter((x)=>x.id == staduim)[0]["events"].length;i++){
            data.filter((x)=>x.id == staduim)[0]["events"][i]["isSelected"]=false;
        }
        
       infos.push(newInfo)
        console.log(infos)
        setReservation(infos);
        setQty(0)
        setListEvent([])
        setData(data)
        setCount(count*infos.length*4)
        setTicket(0)
       }
       
    }
    const next = async() => {
       /*
        if(currentIndex >= 2){
            if(name.trim().length<3 || lastNameUser.trim().length<3 || phone.trim().length<3 || mobile.trim().length<3 || phoneUser.trim().length<3 || nameUser.trim().length<3 || email.trim().length<3 || emailUser.trim().length<3 || geo.trim().length<3 || domain.trim().length<3 || job.trim().length<3    || reservation.length==0  ){
                alert("Veuillez renseigner tous les champs")
            }
            else
            confirm()

        }
        else{
            setCurrentIndex(currentIndex+1);

        }*/
        var res = []
        var resEvent = eventList
    var search = (resEvent.filter((x)=> x.isSelected == false && (x.tickets[0].qty>0 || x.tickets[1].qty>0 || x.tickets[2].qty>0 )));
        for(var i = 0;i<search.length;i++){
            var oneEvent = search[i].tickets;
            for(var j = 0;j<oneEvent.length;j++){
                if(oneEvent[j].qty>0){
                    var newInfo = {
                        stadiumInfo:search[i].idStadium,
                        ticket:oneEvent[j].id,
                        events:[search[i].id],
                        qty:oneEvent[j].qty,
                        uuid:uuidv4()
                    }
                    res.push(newInfo)
                }
            }
            
        }
         /*search = (resEvent.filter((x)=> x.isSelected  && (x.tickets[0].qty>0 )));
        for(var i = 0;i<search.length;i++){
            var oneEvent = search[i].tickets;
            for(var j = 0;j<oneEvent.length;j++){
                if(oneEvent[j].qty>0){
                    var newInfo = {
                        stadiumInfo:search[i].idStadium,
                        ticket:oneEvent[j].id,
                        events:[search[i].id],
                        qty:oneEvent[j].qty,
                        uuid:uuidv4()
                    }
                    res.push(newInfo)
                }
            }
            
        }*/
        console.log(res)
        setReservation(res)
        setCount(45*67*res.length)
        if(name.trim().length<3 || lastNameUser.trim().length<3 ||  phoneUser.trim().length<3 || nameUser.trim().length<3 ||  emailUser.trim().length<3 || res.length==0  ){
            alert("Veuillez renseigner tous les champs")
        }
        else
        confirm()
    }

    const confirm = () => {
        SweetAlert.fire({
            title: 'Confirmation',
            text: 'Voulez vous confirmer cette réservation ?',
            icon: 'warning',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Confirmer',
            showCancelButton: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                attribuate();
            }
        });
    }
    const attribuate = async()=>{
        var res = []
        var resEvent = eventList
    var search = (resEvent.filter((x)=> x.isSelected == false && (x.tickets[0].qty>0 || x.tickets[1].qty>0 || x.tickets[2].qty>0 )));
        for(var i = 0;i<search.length;i++){
            var oneEvent = search[i].tickets;
            for(var j = 0;j<oneEvent.length;j++){
                if(oneEvent[j].qty>0){
                    var newInfo = {
                        stadiumInfo:search[i].idStadium,
                        ticket:oneEvent[j].id,
                        events:[search[i].id],
                        qty:oneEvent[j].qty,
                        uuid:uuidv4()
                    }
                    res.push(newInfo)
                }
            }
            
        }
         search = (resEvent.filter((x)=> x.isSelected  && (x.tickets[0].qty>0 )));
        for(var i = 0;i<search.length;i++){
            var oneEvent = search[i].tickets;
            for(var j = 0;j<oneEvent.length;j++){
                if(oneEvent[j].qty>0){
                    var newInfo = {
                        stadiumInfo:search[i].idStadium,
                        ticket:oneEvent[j].id,
                        events:[search[i].id],
                        qty:oneEvent[j].qty,
                        uuid:uuidv4()
                    }
                    res.push(newInfo)
                }
            }
            
        }
        setDisplay("flex")
            var data = {
                name,
                lastNameUser,
                phone,
                mobile,
                phoneUser,
                nameUser,
                email,
                emailUser,
                geo,
                domain,
                job,
                ncc,
                rccm,
                reservation:res,
                motif
            }
            console.log(data)
            var mx = await requestAxios(data,"cocan/reservation/create","POST")
            console.log(mx)
            if(mx.status == 200){
                console.log(mx.data.data)
                setCurrentIndex(2)
            }
            else{
                alert("une erreur s'est produite")
            }
            setDisplay("none")
    }
    const prev = () => {
        setCurrentIndex(currentIndex-1);
    }
    return <div style={{width:"calc(100vw)",height:"calc(100vh)",display:"flex",flexDirection:"column",backgroundImage:`url("https://www.cocan2023.ci/wp-content/uploads/2023/11/1880X700.jpg")`, backgroundRepeat: "no-repeat",backgroundSize: "cover"}}>
        <div style={{height:"90px",width:"100%",backgroundColor:"#07460E",paddingLeft:"20px",display:"flex", alignItems:"center",flexDirection:"row", justifyContent:"space-between"}}>
            <img src="https://can2023-tickets.com/images/logo.svg" alt="Cocan" width={150} />
                <h1  style={{fontWeight:"bold",color:"white",fontSize:"32px"}}>
                PRE-COMMANDE DES BILLETS GROUPES DE LA CAN 2023 
                </h1>
                <div style={{width:"150px"}} >
                    <h4 style={{color:"white"}}>Bon à savoir         <FontAwesomeIcon icon={faQuestion} style={{fontSize:"24px"}} color="white" onClick={()=>{}}/>
</h4>
                </div>

        </div>
        <div style={{height:"100%",width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
        {
            currentIndex>=2?<div style={{height:"calc(45vh)",width:"calc(25vw)",backgroundColor:"white",borderRadius:"20px",padding:"0px",marginRight:"20px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            
            <strong>Votre commande a été envoyée</strong>
            <img style={{width:"100px", height:"100px",marginTop:"20px"}} src={success}/>
        </div>:null
        }
           {
            currentIndex!=2?
            
            <div style={{height:"95%", width:"calc(80vw)",backgroundColor:"white",borderRadius:"20px",overflowY:"scroll",display:"flex",flexDirection:"column"}}>
                <div style={{width:"100%"}}>
                    <div style={{backgroundColor:"#EF8F1F",height:"65px",width:"100%",display:"flex",alignItems:"center", paddingLeft:"10px"}}>
                        <div style={{cursor:"pointer"}} onClick={()=>{setCurrentTab(0)}}>
                            <label style={{color:"white",cursor:"pointer"}}>Billets Grand Public</label>
                            <div style={{height:"2px",width:"130px",backgroundColor:currentTab==0?"white":"transparent"}}></div>                        
                        </div>
                        <div style={{marginLeft:"20px",cursor:"pointer"}} onClick={()=>{setCurrentTab(1)}}>
                            <label style={{color:"white",cursor:"pointer"}}>Billets Hospitalités</label>
                            <div style={{height:"2px",width:"130px",backgroundColor:currentTab==1?"white":"transparent"}}></div>
                        </div>
                    </div>
                <Row style={{padding:"10px 20px 0px 20px"}}>
                
                <Col md={3}>
                <FormGroup>
                    <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                        Noms (*)
                    </Label>
                    <Input valid={false}  value={nameUser} onChange={(v)=>{setNameUser(v.target.value)}}   />
                </FormGroup>
                
                </Col>
                <Col md={3}>
                
                <FormGroup>
                    <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                        Prénoms (*)
                    </Label>
                    <Input valid={false} value={lastNameUser} onChange={(v)=>{setLastNameUser(v.target.value)}}   />
                </FormGroup>
                </Col>
                <Col md={3}>
                <FormGroup>
                            <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                                Email (*)
                            </Label>
                        <Input valid={false}   value={emailUser} onChange={(v)=>{setEmailUser(v.target.value)}}  />
                        </FormGroup>
                </Col>
                <Col md={3}>
                <FormGroup>
                            <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                                Téléphone (*)
                            </Label>
                            <Input valid={false}  type="number"   value={phoneUser} onChange={(v)=>{setPhoneUser(v.target.value)}}  />
                        </FormGroup>
                </Col>
                <Col md={3}>
                <FormGroup>
                            <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                                Adresse (*)
                            </Label>
                            <Input valid={false}     value={phone} onChange={(v)=>{setPhone(v.target.value)}}  />
                        </FormGroup>
                </Col>
                <Col md={3}>
                <FormGroup>
                            <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                                Code postal (*)
                            </Label>
                            <Input valid={false}  type="text"   value={domain} onChange={(v)=>{setDomain(v.target.value)}}  />
                        </FormGroup>
                </Col>
                <Col md={3}>
                <FormGroup>
                    <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                        Raison Sociale (*)
                    </Label>
                    <Input valid={false} value={name} onChange={(v)=>{setName(v.target.value)}} />
                </FormGroup>
                </Col>
                <Col md={3}>
                <FormGroup>
                    <Label  for="exampleEmail" style={{fontSize:"12px"}}>
                        Ville
                    </Label>
                    <Input valid={false} value={geo} onChange={(v)=>{setGeo(v.target.value)}} />
                </FormGroup>
                </Col>
            </Row>
                </div>
            <div style={{height:"200px"}}>
            {currentTab == 0?
            <DataTable data={eventList.filter((x)=>!x.isSelected)} columns={tableColumns} striped={true} center={true} />:
            <div style={{width:"100%",height:"200px",display:"flex",justifyContent:"center",alignItems:"center"}}>En cours</div>}

            </div>
            </div>:null
           }
        </div>
        <div style={{
            display:display,
            backgroundColor:"rgba(191, 191, 191, 0.7)",width:"calc(100vw)",height:"calc(100vh)",top:0,left:0,position:"fixed",zIndex:9999,justifyContent:"center",alignItems:"center"}}>
                <div style={{width:"120px",height:"120px",backgroundColor:"white",display:"flex",}}>
                    <img src={ballon} style={{borderRadius:"10px"}}/>

                </div>
        </div>
{currentIndex>=2?null:
        <div style={{width:"80px",height:"80px",backgroundColor:"#EF8F1F",bottom:20,right:"calc(12vw)",position:"fixed",borderRadius:"40px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",cursor:"pointer"}} onClick={()=>{next()}}>
        <label style={{color:"white",fontSize:"14px",textAlign:"center",cursor:"pointer",fontWeight:"bold"}}>{total}</label>
        <label style={{color:"white",fontSize:"12px",textAlign:"center",cursor:"pointer",}}>XOF</label>
        <label style={{color:"white",fontSize:"12px",textAlign:"center",cursor:"pointer"}}>Valider </label>
        </div>
}
    </div>
}

export default Index;