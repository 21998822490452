import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <div style={{marginBottom:"15px"}}>
      <div style={{backgroundColor:"#07460E",width:"155px",borderRadius:"10px"}}>
      <img src="https://can2023-tickets.com/images/logo.svg" alt="Cocan" width={150} />
      </div>
      </div>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
