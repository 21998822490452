import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useEffect, useState } from "react";
import excel from "../../../../assets/img/excel.png";
import loader from "../../../../assets/img/load.gif";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faEye } from '@fortawesome/free-solid-svg-icons'
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { requestAxios } from "common/common";
import { Button, Input, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
export default function ColumnsTable(props) {
  const [datas,setDatas] = useState([])
  const { columnsData, tableData } = props;
  const [tickets,setTickets] = useState([])
  const [isLoad,setIsLoad] = useState(false)

  useEffect(()=>{
    init2()
    init()
  },[])
  const exports = async() =>{
    setIsLoad(true)
    try{
      var mx = await requestAxios({},"export","POST");
      console.log(mx.data);
      if(mx.status == 200){
          window.open("https://cocan-courrier.com/ws/files/"+mx.data.data, "_blank");
      }
      setIsLoad(false)

    }
    catch(e){
      setIsLoad(false)
    }
}
  const init2 = async() =>{
    var mx = await requestAxios({},"cocan/event/list-ticket","POST");
    console.log(mx.data);
    if(mx.status == 200){
        setTickets(mx.data.data[1].tickets)
    }
}
  const init = async()=>{
    try{
      var mx = await requestAxios({},"admin/cocan/reservation/list","POST")
      console.log(mx.data["data"])
      if(mx.status == 200){
        setDatas(mx.data["data"])
      }
    }
    catch(e){}
  }
  const confirm = () => {
    SweetAlert.fire({
        title: 'Confirmation',
        text: 'Voulez vous soumettre cette confirmation ??',
        icon: 'warning',
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Confirmer',
        showCancelButton: true
    }).then(async (result) => {
        if (result.isConfirmed) {
            submit();
        }
    });
}
  const submit = async()=>{
    var data = currentRow;
    var mx = await requestAxios(data,"admin/cocan/reservation/attribuate","POST")
    console.log(mx.data["data"])
    if(mx.status == 200){
      toggle()
      init()
    }
    else{}
  }

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const tableColumns = [
    {
      name: 'N°',
      width:50,
      cell: (row) => (
        <strong>{datas.indexOf(row)+1}</strong>
    )
  },
    {
        name: 'Raison sociale',
        selector: 'name',
        cell: (row) => (
          <strong>{row.name}</strong>
      )
    },
    {
      name: 'Domaine',
      selector: 'domain',
      sortable: false
  },
  {
    name: 'Email Interlocuteur',
    selector: 'emailUser',
    sortable: false
},
  {
    name: 'Email',
    selector: 'email',
    sortable: false
},
,{
  name: 'Statut',
  button: true,
  width: 250,
  cell: (row) => (
      <div style={{backgroundColor:row.state==0?"grey":"green",padding:"5px 10px 5px 10px",borderRadius:"20px",color:"white"}}>
          {
            row.state == 0?"Nouveau":"Traité"
          }
      </div>
  )
},
{
  name: 'Situation Géographique',
  selector: 'geo',
  sortable: false
},{
  name: 'Actions',
  button: true,
  width: 250,
  cell: (row) => (
      <div>
          {
            row.state == 0?<FontAwesomeIcon icon={faEdit} color="green" onClick={()=>{toggle(row)}}/>:          <FontAwesomeIcon icon={faEye} color="black" onClick={()=>{toggle(row)}} style={{marginLeft:"10px"}}/>

          }
      </div>
  )
}
];
const [isOpen,setIsOpen] = useState(false)
const [currentRow,setCurrentRow] = useState(null)
const toggle = (v)=>{
  setIsOpen(!isOpen)
  setCurrentRow(v)
}
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Réservations ({datas!=null?datas.length:""})
        </Text>
        <img src={!isLoad?excel:loader} style={{width:"25px",height:"25px",cursor:"pointer"}} onClick={()=>{exports()}}/>
      </Flex>
      <DataTable data={datas} columns={tableColumns} striped={true} center={true} />
      <Offcanvas isOpen={isOpen} direction="end" toggle={()=>{toggle()}}>
    <OffcanvasHeader >
      <div style={{display:"flex",flexDirection:"row",alignItems:"space-between",width:"100%"}}>
      Enrégistrement des modifications
      <div style={{backgroundColor:currentRow!=null && currentRow.state==0?"grey":"green",width:"80px",borderRadius:"20px",color:"white",display:"flex",justifyContent:"center",alignItems:"center",height:"30px",marginLeft:"10px"}}>
          {
           currentRow!=null && currentRow.state == 0?"Nouveau":"Traité"
          }
      </div>
      </div>
     
    </OffcanvasHeader>
    <OffcanvasBody style={{display:"flex",flexDirection:"column"}}>
      <strong>
        Informations commandes
      </strong>
      {
        currentRow!=null ?
        <div style={{width:"100%",height:"100%",overflowY:"auto"}}>
          {
            currentRow.reservation.map((x)=>{
              return <div style={{display:"flex",flexDirection:"column"}}>
                <label style={{marginTop:"20px"}}>Informations stade</label>
                <strong>{x.nameStadium}</strong>
                <label>Quantité souhaité : {x.qty}</label>
                <label  style={{marginTop:"10px"}}>Evenement</label>
                <label>{tickets.filter((e)=>e.id == x.ticket).length>0?tickets.filter((e)=>e.id == x.ticket)[0]["name"]:""}</label>

                {
                  
                  x.nameEvent.map((v)=>{
                    return <div>
                      <label>{v.name}</label>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>Quantité possible à attribuer : <div style={{width:"80px"}}>
                      {
                        currentRow.state == 0?
                        <Input min={0} max={x.qty} placeholder="0" type="number" onChange={(value)=>{
                          var index = currentRow.reservation.indexOf(x);
                          if(index!=-1){
                           var index2 = currentRow.reservation[index].nameEvent.indexOf(v);
                           if(index2!=-1){
                             currentRow.reservation[index].nameEvent[index2].qty=value.target.value;
                             console.log(currentRow);
                           }
                          }
                       }}  disabled={currentRow.state}/>:v.qty
                      }
                      
                        </div></div>
                    </div>
                  })
                }
              </div>
            })
          }
        </div>:null
      }
      <div style={{width:"100%",height:1,backgroundColor:"grey"}}></div>
      <div style={{height:"70px",width:"100%",display:"flex",justifyContent:"end",alignItems:"center"}}>
      {
        currentRow!=null && currentRow.state == 0?
        <Button color="primary"  style={{marginLeft:"10px",backgroundColor:"#07460E",fontSize:"14px",height:"38px",width:"100px"}} onClick={()=>{
          console.log(currentRow)
          confirm();
        }}>Enrégistrer</Button>
:null  
      }
      </div>
    </OffcanvasBody>
  </Offcanvas>
    </Card>
  );
}
